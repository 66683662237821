import React from 'react';
import {Show,
        TabbedShowLayout,
        Tab,
        ReferenceField,
        TextField,
        SelectField,
        } from 'react-admin'
import DescargarReporteContadores from './descargarReporteContadores';
import ActualizarUbicacion from '../accionesMaquinas/actualizarUbicacion';
import ShowHistorialContadores from './showHistorialContadores';
import ShowHistorialUbicaciones from './showHistorialUbicaciones';


const MaquinaShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Detalles" >
                
                <ReferenceField label="inventario"  source="inventario_id" reference="inventarios" linkType={false}>
                <TextField source="prefijo" />
                </ReferenceField>
                <TextField label="Serie" source="serie"/>
                <SelectField source="tipo" choices={[
                    { id: 'Habilidad', name: 'Habilidad' },
                    { id: 'Videojuego', name: 'Videojuego' },
                ]} />
                <ReferenceField label="Modelo"  source="modelo_id" reference="modelos" linkType={false}>
                    <TextField source="nombre" />
                </ReferenceField>
            <TextField source="descripcion" />
            <ActualizarUbicacion {...props}/>
            </Tab>
             <Tab label="Licencias" path="licencias">
                 
            </Tab>
            <Tab label="Contadores" path="contadores">
                <ShowHistorialContadores {...props}/>
            </Tab>
            <Tab label="Ubicaciones" path="ubicaciones">
                <ShowHistorialUbicaciones {...props}/>
            </Tab>
            <Tab label="Servicios" path="servicios">

            </Tab>
            <Tab label="Reportes" path="reportes">
                <DescargarReporteContadores {...props}/>

            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default MaquinaShow;