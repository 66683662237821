import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import {FormWithRedirect,useCreate,useNotify,NumberInput,SaveButton, ReferenceInput,AutocompleteInput} from 'react-admin'



const useStyles = makeStyles({
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ActualizarContadorEntrada(props) {
  const [open, setOpen] = React.useState(false);
  const [create, { loading }] = useCreate('historialcontadores');
  const notify = useNotify();

  const classes  = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleSubmit = async  values =>{
    console.log(props.record)
    values["maquina_id"]  = props.record.maquina_id;
    values["tipo"] = 0;

    create(
        {payload : {data : values}},{
            onSuccess : ({data}) => {
                setOpen(false)
                notify('Contador de entrada actualizado')
            },
            onFailure :({error}) => {
                notify(error.message, 'error');
            }
        }
    )
  }
  return (
    <React.Fragment>
        <Tooltip title="Actualizar contador entrada">
            <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
                <ExpandLessIcon/>
            </IconButton>
        </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Actualizar Contador de entrada"}</DialogTitle>
        <FormWithRedirect
            resource="historialcontadores"
            save={handleSubmit}
            render={({
             handleSubmitWithRedirect,
             pristine,
             saving
        }) => (
        <>
            <DialogContent>
                <NumberInput
                    label="Valor"
                    source="value"
                />
                 <ReferenceInput  label="Causa" source="causa_id" reference="causacont" filterToQuery={searchText => (
                { 
                    type :"TEXT",
                    data :{nombre : searchText }}
                )}
                >
                <AutocompleteInput optionText="nombre" options={{suggestionsContainerProps: { className: classes.suggestionsContainerOpen }}} />
            </ReferenceInput>
            </DialogContent>
            <DialogActions>
                <Button
                    label="Cerrar"
                    onClick={handleClose}
                >
                    
                </Button>
                <SaveButton
                    handleSubmitWithRedirect={
                        handleSubmitWithRedirect
                    }
                    pristine={pristine}
                    saving={saving}
                />
            </DialogActions>
        </>
    )}
/>
      </Dialog>
    </React.Fragment>
  );
}


export default ActualizarContadorEntrada;