import React, { useCallback } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import debounce from 'lodash/debounce'
import CircularProgress from "@material-ui/core/CircularProgress";




export default function BuscarMunicipio(props) {
	const [municipios, setMunicipio] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState(null);
	const loading = open && municipios.length === 0;

	const getMunicipioDelayed = useCallback(
		debounce(input => {
			const searchParams = JSON.stringify({
				'type': 'TEXT',
				'data': { 'nombre': input, 'estado_id': props.estado_id },
				'range': [0, 200],
				'sort': ["id", "DESC"]
			});

			fetch(process.env.REACT_APP_API_KEY + "/municipios?filter=" + searchParams, { headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` } })
				.then(response => response.json())
				.then(res => {
					setMunicipio(res);
				});
		}, 2000), []
	)


	return (
		<React.Fragment>
			<Autocomplete
				id="buscar-estado-autocomplete"
				open={open}
				onChange={(event, value) => {
					console.log("nueva opcion", value);
					props.updateMunicipio(value.id);
					setValue(value)
				}}
				onOpen={() => {
					setOpen(true)
				}}
				onClose={() => {
					setOpen(false);
				}}
				getOptionSelected={(option, value) => option.nombre === value.nombre}
				getOptionLabel={option => option.nombre}
				options={municipios}
				loading={loading}
				renderInput={params => (
					<TextField
						{...params}
						label="Buscar Municipio"
						variant="outlined"
						onChange={ev => {
							if (ev.target.balue !== "" || ev.target.value !== null) {
								getMunicipioDelayed(ev.target.value);
							}
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</React.Fragment>
	);
}
