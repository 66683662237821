import React from 'react'
import ActualizarContadorEntrada from './actualizarContadorEntrada'
import ActualizarContadorSalida  from './actualizarContadorSalida'
import ActualizarIngreso from './actualizarIngreso'
import BorrarMaquinaAsignada from './borrarMaquinaAsignadada'
import ActualizarUbicacion from './actualizarUbicacion'
function AccionesMaquinas(props){
    
    return (<React.Fragment>
        <ActualizarContadorEntrada  {...props}/>
        <ActualizarContadorSalida {...props}/>
        <ActualizarIngreso   {...props}/>
        <ActualizarUbicacion {...props}/>
        <BorrarMaquinaAsignada {...props}/>
    </React.Fragment>);

}

export default AccionesMaquinas;