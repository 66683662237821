import React from 'react'
import { Create, SimpleForm, TextInput} from 'react-admin'

const InventarioCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="prefijo" />
            <TextInput source="descripcion" />
        </SimpleForm>
    </Create>
);

export default InventarioCreate;