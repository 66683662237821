
import React from 'react';

const SincronizadoField = (props) => {

	return (
		<span>{props.record.sincronizado ? 'Sincronizado' : 'No sincronizado'}</span>
	)
}

export default SincronizadoField