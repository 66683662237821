import React from 'react';
import { DateField, List, Datagrid, TextField , ReferenceField} from 'react-admin';
import SincronizadoField from './field-sincronizado';
const ListReportDiaRuta = (props) => {
	return (
		<List {...props}>
			<Datagrid rowClick="show">
				<TextField source="nombre_ruta" label="Ruta" />
				<ReferenceField label="Creado Por" source="creado_por" reference="usuarios" link={false}>
					<TextField source="nombre" />
				</ReferenceField>

				<SincronizadoField source="sincronizado" label="Estado" />
				<ReferenceField label="Sincronizado Por" source="sincronizado_por" reference="usuarios" link={false}>
					<TextField source="nombre" />
				</ReferenceField>


				<DateField source="created_at" showTime label="Creado" />
				<DateField source="updated_at" showTime label="Ultima actualizacion" />

			</Datagrid>
		</List>
	)
}

export default ListReportDiaRuta;