import React from 'react'
import {List, Datagrid, TextField} from 'react-admin'

const InventarioList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nombre" />
            <TextField source="prefijo" />
            <TextField source="descripcion" />
        </Datagrid>
    </List>
);
export default InventarioList;