import React from 'react';
import {ReferenceField,Datagrid, TextField, List} from 'react-admin';


const RutaList = props => (
    <List{...props}>
        <Datagrid rowClick="show">
            <TextField source="nombre" />
            <TextField source="descripcion" />
            <ReferenceField source="encargado" reference="usuarios" link={false}>
                <TextField source="nombre" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default RutaList