import React from 'react';
import {Create, SimpleForm, TextInput, NumberInput,  } from 'react-admin';

const usuarioCreate = props =>(
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="direccion" />
            <TextInput source="colonia" />
            <TextInput source="ciudad" />
            <TextInput source="telefono" />
            <TextInput source="celular" />
            <NumberInput source="lvl" />
            <TextInput source="username" />
            <TextInput source="password" type="password"/>            
        </SimpleForm>
    </Create>
);

export default usuarioCreate;