import React, { useState } from 'react'
import QuitarClienteRuta from './quitarClienteRuta'
import keyBy from 'lodash/keyBy'
import { useListController, ListContextProvider, useQuery, Datagrid, TextField, Pagination, Loading, ReferenceField, SaveContextProvider } from 'react-admin'

const GridClientesRuta = (props) => {
    const [page, setPage] = useState(1);
    const perPage = 500;
    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'detalleruta',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'nombre', order: 'ASC' },
            filter: { id_ruta: props.record.id, activo: 1 },
        }
    });

    const controllerProps = useListController(props);
    if (!loading) {
        controllerProps.data = keyBy(data, 'id');
        controllerProps.ids = data.map(({ id }) => id)
        controllerProps.currentSort = { field: 'id', order: 'ASC' }
    }

    if (loading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    return (
        <>
                <ListContextProvider value={controllerProps}>
                    <Datagrid
                        data={keyBy(data, 'id')}
                        ids={data.map(({ id }) => id)}
                        currentSort={{ field: 'nombre', order: 'ASC' }}
                    >
                        
                        <ReferenceField {...props}
                            label="Nombre"
                            source="id_cliente"
                            reference="clientes"
                            link={false}>
                            <TextField source="nombre" />

                        </ReferenceField>

                        <QuitarClienteRuta {...props} />



                    </Datagrid>

                    <Pagination
                        page={page}
                        perPage={perPage}
                        setPage={setPage}
                        total={total}
                    />
                </ListContextProvider>

        </>
    )
}

export default GridClientesRuta;