
import React from 'react'
import {
    Edit,
    SimpleForm,
    ReferenceInput,
    NumberInput,
    SelectInput,
    TextInput,
    AutocompleteInput
} from 'react-admin'

const MaquinaEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput source="inventario_id" reference="inventarios"><SelectInput optionText="prefijo" /></ReferenceInput>
            <NumberInput source="serie" />
            <SelectInput source="tipo" choices={[
                { id: 'Habilidad', name: 'Habilidad' },
                { id: 'Videojuego', name: 'Videojuego' },
            ]} />
            <ReferenceInput source="modelo_id" reference="modelos" perPage={200} sort={{ field: 'nombre', order: 'DESC' }} >
                <SelectInput optionText="nombre" />
            </ReferenceInput>

            <TextInput source="descripcion" />
        </SimpleForm>
    </Edit>
);
export default MaquinaEdit