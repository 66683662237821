import React from 'react';
import {Edit,SimpleForm,TextInput} from 'react-admin';

const causaContadoresEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="descripcion" />
        </SimpleForm>
    </Edit>
);

export default causaContadoresEdit;