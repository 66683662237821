import React from 'react'
import {List,
        Datagrid,
        ReferenceField,
        TextField,
        SelectField} from 'react-admin'
import BuscarMaquinaFilter from './buscarMaquinaFilter';

const MaquinaList = props => (
    <List {...props} filters={<BuscarMaquinaFilter/>}>
        <Datagrid rowClick="show" >
            <ReferenceField label="inventario"  source="inventario_id" reference="inventarios" link={false}>
                <TextField source="prefijo" />
            </ReferenceField>
              <TextField label="Serie" source="serie" />
              <SelectField source="tipo" choices={[
                { id: 'Habilidad', name: 'Habilidad' },
                { id: 'Videojuego', name: 'Videojuego' },
            ]} />
            <ReferenceField label="Modelo"  source="modelo_id" reference="modelos" link={false}>
                <TextField source="nombre" />
            </ReferenceField>
            <TextField source="descripcion" />
         </Datagrid>
    </List>
);

export default MaquinaList