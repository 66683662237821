import React from 'react'
import {Edit, SimpleForm, TextInput } from 'react-admin'
const InventarioEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="prefijo" />
            <TextInput source="descripcion" />
        </SimpleForm>
    </Edit>
);

export default InventarioEdit;
