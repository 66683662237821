import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const GridDetalleCorte = (props) => {
  let data = props.data;

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="Detalle Corte">
          <TableHead>
            <TableRow>
              <TableCell>Maquina</TableCell>
              <TableCell>Ingreso real</TableCell>
              <TableCell>Ingreso diversa</TableCell>
              <TableCell>Ingreso cliente</TableCell>
              <TableCell>Contador entrada</TableCell>
              <TableCell>Contador entrada servicio</TableCell>
              <TableCell>Contador salida</TableCell>
              <TableCell>Contador salida servicio</TableCell>

            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row) => {
              console.log(row);
              let c_e;
              let c_e_s;
              let c_s;
              let c_s_s;
              let contador_e = row.maquina_info.contadores.filter(obj => {
                switch (obj.tipo) {
                  case 1:
                    c_e = obj.value;
                    break;
                  case 2:
                    c_e_s = obj.value;
                    break;
                  case 3:
                    c_s = obj.value;
                    break;
                  case 4:
                    c_s_s = obj.value;
                    break;
                }
              })
              return (
                <TableRow key={row.id}>
                  <TableCell align="center">{`${row.maquina_info.inventario.prefijo}-${row.maquina_info.serie}`}</TableCell>
                  <TableCell align="center">{`$${row.cantidad}`}</TableCell>
                  <TableCell align="center">{row.cantidad - (row.cantidad * (props.porcentaje / 100))}</TableCell>
                  <TableCell align="center">{row.cantidad * (props.porcentaje / 100)}</TableCell>
                  <TableCell>{c_e}</TableCell>
                  <TableCell>{c_e_s}</TableCell>
                  <TableCell>{c_s}</TableCell>
                  <TableCell>{c_s_s}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

    </React.Fragment>
  )
}

export default GridDetalleCorte;