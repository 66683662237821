import clienteList from './list'
import ClienteCreate from './create'
import ClienteShow from './show'
import ClienteEdit from './edit'

export default {
    list : clienteList,
    create : ClienteCreate,
    show : ClienteShow,
    edit : ClienteEdit
}