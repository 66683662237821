import RutaList from "./list";
import RutaEdit from "./edit";
import RutaCreate from "./create";
import RutaShow from './show'


export default {
    list: RutaList,
    edit : RutaEdit,
    create : RutaCreate,
    show : RutaShow,
}