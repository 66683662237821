import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import { FormWithRedirect, useDataProvider, useNotify, useRefresh, NumberInput, SaveButton, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin'



const useStyles = makeStyles({
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AsignarMaquina(props) {
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  let data;


  const dataProvider = useDataProvider();
  const crear = () => dataProvider
    .create('maquinasAsignadas', { data: data })
    .then(response => {
      setOpen(false);
      notify("Maquina asignada correctamente",)
      refresh();
    })
    .catch((error) => {
      setOpen(false);
      notify(error.message, 'error')

    })

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSubmit = async values => {
    values["cliente_id"] = props.record.id
    data = values;
    crear();

  }
  return (
    <React.Fragment >
      <Tooltip title="Asignar maquina">
        <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
          <AddBoxRoundedIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Asignar maquina al cliente"}</DialogTitle>
        <FormWithRedirect
          resource="maquinasAsignadas"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <ReferenceInput source="inventario_id" perPage={9999} reference="inventarios">
                  <SelectInput optionText="prefijo" />
                </ReferenceInput>
                <NumberInput source='serie' />
              </DialogContent>
              <DialogActions>
                <Button
                  label="Cerrar"
                  onClick={handleClose}
                >

                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </React.Fragment>
  );
}


export default AsignarMaquina;