import React from 'react';
import {List,Datagrid,TextField} from 'react-admin';
import UpdateStock from './updateStock'

const ProductoList = props =>(
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nombre"/>
            <TextField source="descripcion"/>
            <UpdateStock {...props}/>
        </Datagrid>
    </List>
);

export default ProductoList;