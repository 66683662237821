import { Show, SimpleShowLayout } from 'react-admin';
import React, { useEffect } from 'react'
import DetalleCorte from './detalle-corte'
import { useState } from 'react';
import { Typography } from '@material-ui/core';
import GridMovimientoEfectivoRuta from './grid-movimiento-efectivo-ruta';

const ShowDiaRuta = (props) => {
	const apiUrl = process.env.REACT_APP_API_KEY;
	const token = localStorage.getItem('token');
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const getData = () => {
		setLoading(true);
		fetch(`${apiUrl}/diaruta/detalle/${props.id}`, {
			method: 'GET', headers: {
				'Accept': 'application/json',
				'Authorization': `${token}`,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}
		}).then(e => e.json()

		).then(data => {
			setData(data);
		})
			.catch((e) => {
				console.log(e);
			}).finally(() => {
				setLoading(false);
			})
	}
	useEffect(
		getData, []
	)
	console.log(props);
	if (loading || data === {}) {
		return 'cargando';
	}
	console.log(data)
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<Typography align="center">Detalles corte cliente</Typography>
				<DetalleCorte data={data.corte} {...props}></DetalleCorte>
				<Typography align="center">Movimiento de dinero</Typography>
				<GridMovimientoEfectivoRuta data={data.movimiento_dinero}/>
				<Typography align="center">Maquinas devueltas</Typography>
			</SimpleShowLayout>
		</Show>
	)
}

export default ShowDiaRuta;