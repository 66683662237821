import React from 'react';
import {Edit, SimpleForm, TextInput, ReferenceField, TextField} from 'react-admin';

const ModeloEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="descripcion" />
            <ReferenceField label="Creado por" source="created_by" reference="usuarios">
                <TextField source="nombre" />
            </ReferenceField>
            <ReferenceField label="Modificado por" source="updated_by" reference="usuarios">
                <TextField source="nombre" />
            </ReferenceField>
        </SimpleForm>
    </Edit>
);

export default ModeloEdit;
