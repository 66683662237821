import React from 'react';
import {Edit,SimpleForm,TextInput,NumberInput} from 'react-admin';

const usuarioEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="direccion" />
            <TextInput source="colonia" />
            <TextInput source="ciudad" />
            <TextInput source="telefono" />
            <TextInput source="celular" />
            <NumberInput source="lvl" />
        </SimpleForm>
    </Edit>
);

export default usuarioEdit;