import React, { Fragment } from 'react'
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer, SelectInput } from 'react-admin'
import BuscarEstado from './buscarEstado';
import BuscarLocalidad from './buscarLocalidad';
import BuscarMunicipio from './buscarMunicipio';

const ClienteCreate = props => {
  const [render, setRender] = React.useState(false);
  return (
    <React.Fragment>

      <Create {...props}>
        <SimpleForm>
          <TextInput source="nom_com" />
          <TextInput source="nombre" />
          <TextInput source="telefono" />
          <TextInput source="celular" />
          <TextInput source="domicilio" />
          <TextInput source="colonia" />


          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const updateEstado = (e) => {
                formData.estado_id = e;
                setRender(false);
                console.log(formData);
              }
              const updateMunicipio = (e) => {
                formData.municipio_id = e;
                console.log(formData);
              }

              const updateLocalidad = (e) => {
                formData.localidad_id = e;
              }

              return (
                <React.Fragment>
                  <BuscarEstado updateEstado={updateEstado} />
                  {formData.estado_id ? <BuscarMunicipio estado_id={formData.estado_id} updateMunicipio={updateMunicipio}/>: ''}
                  {formData.municipio_id ? <BuscarLocalidad municipio_id={formData.municipio_id} updateLocalidad={updateLocalidad}/> : ''}
                </React.Fragment>
              )
            }
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </React.Fragment>
  )
};

export default ClienteCreate;