import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import {FormWithRedirect,useCreate,useNotify,ReferenceInput,SelectInput,SaveButton, TextInput} from 'react-admin'



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ActualizarUbicacion(props) {
  const [open, setOpen] = React.useState(false);
  const [create] = useCreate('historialubicacion');
  const notify = useNotify();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleSubmit = async  values =>{
    values["maquina_id"]  = props.record.id;
    create(
        {payload : {data : values}},{
            onSuccess : ({data}) => {
                setOpen(false)
                notify('Ubicacion actualizada')
            },
            onFailure :({error}) => {
                notify(error.message, 'error');
            }
        }
    )
  }
  return (
    <React.Fragment>
        <Tooltip title="Actualizar Ubicación">
            <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
                <AddLocationIcon/>
            </IconButton>
        </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Actualizar Ubicacion"}</DialogTitle>
        <FormWithRedirect
            resource="historialubicacion"
            save={handleSubmit}
            render={({
             handleSubmitWithRedirect,
             pristine,
             saving
        }) => (
        <>
            <DialogContent>
                <ReferenceInput label="Ubicacion" source="ubicacion_id" reference="ubicaciones">
                    <SelectInput optionText="nombre"/>
                </ReferenceInput>

                <TextInput label="Nota" source="nota" fullWidth={true}/>
            </DialogContent>
            <DialogActions>
                <Button
                    label="Cerrar"
                    onClick={handleClose}
                >
                    
                </Button>
                <SaveButton
                    handleSubmitWithRedirect={
                        handleSubmitWithRedirect
                    }
                    pristine={pristine}
                    saving={saving}
                />
            </DialogActions>
        </>
    )}
/>
      </Dialog>
    </React.Fragment>
  );
}


export default ActualizarUbicacion;