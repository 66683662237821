import React from "react";
import { useMutation, useNotify, useRefresh } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const BorrarMaquinaAsignada = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [borrarMaquina, { }] = useMutation(
        {
            type: 'update',
            resource: 'maquinasAsignadas',
            payload: { id: props.record.id, data: { activo: false } },
        },
        {
            onSuccess: ({ data }) => {
                notify('Maquina desasignada ', 'info');
                refresh();
            },
            onFailure: (error) => notify(`Error al desasignar la maquina: ${error.message}`, 'warning'),
        }
    );
    return (
        <Tooltip title="Borrar Maquina de cliente">
            <IconButton variant="outlined" color="primary" onClick={borrarMaquina}>
                <DeleteForeverIcon />
            </IconButton>
        </Tooltip>
    )
}
export default BorrarMaquinaAsignada