import React, { useState } from 'react'
import keyBy from 'lodash/keyBy'
import { useListController, useQuery, Datagrid, TextField, Pagination, Loading, ReferenceField, ListContextProvider } from 'react-admin'
import AccionesMaquinas from '../accionesMaquinas/accionesMaquina'


const MaquinasAsignadasGrid = (props) => {
    const [page, setPage] = useState(1);
    const perPage = 50;
    const { data, totalMaq, loading, error } = useQuery({
        type: 'getList',
        resource: 'maquinasAsignadas',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'id', order: 'ASC' },
            filter: {cliente_id : props.record.id, activo : 1},
        }
    });
        
    const controllerProps = useListController(props);
    if(!loading){
        controllerProps.data= keyBy(data, 'id');
        controllerProps.ids= data.map(({ id }) => id)
        controllerProps.currentSort={ field: 'id', order: 'ASC' }
    }
    if (loading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    return (
        <ListContextProvider value={controllerProps}>
            <Datagrid>
                <ReferenceField {...props}
                    label="Inventario"
                    source="maquina_id" 
                    reference="maquinas" 
                    link={false}>
                        <ReferenceField {...props}
                            source = "inventario_id"
                            reference = "inventarios"
                            link={false}>
                                <TextField source="prefijo"/> 
                        </ReferenceField>
                </ReferenceField>


                <ReferenceField {...props}
                    label="Serie"
                    source="maquina_id" 
                    reference="maquinas" 
                    link={false}>
                    <TextField source="serie"/> 
                </ReferenceField>

                <ReferenceField {...props}
                    label="Tipo"                  
                    source="maquina_id" 
                    reference="maquinas" 
                    link={false}>
                    <TextField source="tipo"/> 
                </ReferenceField>
                <AccionesMaquinas {...props}/>
                </Datagrid>
            <Pagination
                page={page}
                perPage={perPage}
                setPage={setPage}
                total={totalMaq}
            />
        </ListContextProvider >
    )
}

export default MaquinasAsignadasGrid;