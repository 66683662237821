 
import React from 'react'
import {Edit,SimpleForm,TextInput,ReferenceInput,AutocompleteInput,FormDataConsumer} from 'react-admin'
import BuscarEstado from './buscarEstado';
import BuscarLocalidad from './buscarLocalidad';
import BuscarMunicipio from './buscarMunicipio';


const ClienteEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nombre comercial" source="nom_com" />
            <TextInput source="nombre" />
            <TextInput source="telefono" />
            <TextInput source="celular" />
            <TextInput source="domicilio" />
            <TextInput source="colonia" />
            <FormDataConsumer>
            {({ formData, ...rest }) => {
              const updateEstado = (e) => {
                formData.estado_id = e;
              }
              const updateMunicipio = (e) => {
                formData.municipio_id = e;
              }

              const updateLocalidad = (e) => {
                formData.localidad_id = e;
              }

              return (
                <React.Fragment>
                  <BuscarEstado updateEstado={updateEstado} />
                  {formData.estado_id ? <BuscarMunicipio estado_id={formData.estado_id} updateMunicipio={updateMunicipio}/>: ''}
                  {formData.municipio_id ? <BuscarLocalidad municipio_id={formData.municipio_id} updateLocalidad={updateLocalidad}/> : ''}
                </React.Fragment>
              )
            }
            }
          </FormDataConsumer> 
        </SimpleForm>
    </Edit>
);

export default ClienteEdit;
