import React from 'react';
const DescargarReporteContadores= props =>{

    const descargarReporte = () =>{
        var misCabeceras = new Headers();
         const token = localStorage.getItem('token');
        misCabeceras.set('Authorization', `${token}`);
        misCabeceras.set('Content-Type' , 'application/json');

	    const apiUrl = process.env.REACT_APP_API_KEY;
        var miInit = { method: 'GET',
               headers: misCabeceras,
               mode: 'cors',
               cache: 'default' };
        fetch(`${apiUrl}/reporteMaquinaContadores/${props.record.id}`,miInit)
        .then(response => {
            response.blob().then(blob => {
                    console.log(response.headers.get('Filename'));
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = response.headers.get('Filename');//`ReporteContador_${date.getDay()}-${date.getMonth()+1}-${date.getFullYear()}.xlsx`;
					a.click();
				});
        })
    };

    return(
        <button onClick={descargarReporte}>Reporte Contadores</button>
    );
}

export default DescargarReporteContadores;