 
import React from 'react'
import {Create,
        SimpleForm,
        ReferenceInput,
        SelectInput,
        TextInput} from 'react-admin'

const RutaCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="descripcion" />
            <ReferenceInput source="encargado" reference="usuarios"><SelectInput optionText="nombre" /></ReferenceInput>
        </SimpleForm>
    </Create>
);

export default RutaCreate;