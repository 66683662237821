import React from 'react';
import {List, Datagrid, TextField, NumberField,DateField} from 'react-admin';


const usuarioList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nombre" />
            <TextField source="direccion" />
            <TextField source="colonia" />
            <TextField source="ciudad" />
            <TextField source="telefono" />
            <TextField source="celular" />
            <NumberField source="lvl" />
            <TextField source="username" />
        </Datagrid>
    </List>
);

export default usuarioList; 