import React, { useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import debounce from 'lodash/debounce'
import Button from '@material-ui/core/Button';
import { useNotify } from "ra-core";



export default function SearchClientAutoComplete(props) {
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const [value, setValue] = React.useState(null);
	const loading = open && options.length === 0;

	const notify = useNotify();
	const apiUrl = process.env.REACT_APP_API_KEY;
	const asignarClienteToRuta = () => {
		if (value === null) return;
		let ruta_id = props.record.id;

		let options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": localStorage.getItem("token")
			},
			body: JSON.stringify({ 'id_ruta': ruta_id, 'id_cliente': value.id })
		}
		fetch(`${apiUrl}/detalleruta`, options)
			.then(res => {
				if (!res.ok) {
					res.json().then(error => {
						if (error.message !== undefined) {
							notify(error.message, "error");
						}
					});
				} else {
					res.json()
				}
			}
			)
			.then(data => {
				console.log(data.message)
				notify("cliente agregado correctamente ")
			})
			.catch(err => {
				//notify(err.message, 'error')
			});
	}
	const getOptionsDelayed = useCallback(
		debounce(input => {
			const searchParams = JSON.stringify({
				'type': 'TEXT',
				'data': { 'nombre': input }
			});
			console.log(searchParams);
			fetch(process.env.REACT_APP_API_KEY + "/clientes?filter=" + searchParams, { headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` } })
				.then(response => response.json())
				.then(res => {
					setOptions(res);
				});
		}, 2000), [],
	)

	const onChangeHandle = async value => {
		console.log(value);
	};

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<React.Fragment>
			<Autocomplete
				id="search-client-autocomplete"
				style={{ width: 300 }}
				open={open}
				onChange={(event, newValue) => { setValue(newValue) }}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				getOptionSelected={(option, value) => option.nombre === value.nombre}
				getOptionLabel={option => option.nombre}
				options={options}
				loading={loading}
				renderInput={params => (
					<TextField
						{...params}
						label="Cliente"
						variant="outlined"
						onChange={ev => {
							// dont fire API if the user delete or not entered anything
							if (ev.target.value !== "" || ev.target.value !== null) {
								getOptionsDelayed(ev.target.value);
							}
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
			<Button variant="contained" color="primary" onClick={() => {
				asignarClienteToRuta();
			}}>Guardar</Button>
		</React.Fragment>
	);
}