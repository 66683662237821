import React from "react";
import { useMutation, useNotify, useRefresh } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const QuitarClienteRuta = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [borrarCliente, { }] = useMutation(
        {
            type: 'update',
            resource: 'detalleruta',
            payload: { id: props.record.id, data: { activo: 0 } },
        },
        {
            onSuccess: ({ data }) => {
                notify('Cliente quitado de la ruta', 'info');
                refresh();
            },
            onFailure: (error) => notify(`Error al quitar al cliente de la ruta : ${error.message}`, 'warning'),
        }
    );
    return (
        <Tooltip title="Quitar cliente asignado a una ruta">
            <IconButton variant="outlined" color="primary" onClick={borrarCliente}>
                <DeleteForeverIcon />
            </IconButton>
        </Tooltip>
    )
}
export default QuitarClienteRuta; 