import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import {FormWithRedirect,useDataProvider,useNotify, useRefresh, SaveButton, ReferenceInput,AutocompleteInput} from 'react-admin'
import SearchClientAutoComplete from './SearchClientAutocomplete';



const useStyles = makeStyles({
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
});


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AsignarClienteRuta(props) {
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  let data;
  
 
  const dataProvider = useDataProvider();
  const crear = () => dataProvider
  .create('detalleruta',{data : data})
  .then(response => {
    setOpen(false);
    notify("Cliente Asignado correctamente",)
    refresh();
  })
  .catch((error) => {
    setOpen(false);
    notify(error.message,'error')
    
  })
  
  const classes  = useStyles();
  const handleClickOpen = () => {
      setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    
    const handleSubmit =  async values =>{
        values["id_ruta"] = props.record.id
        data = values;
        crear();
  }
  return (
    <React.Fragment >
        <Tooltip title="Asignar Cliente">
            <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
              <PersonAddRoundedIcon/>
            </IconButton>
        </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Asignar cliente a ruta"}</DialogTitle>
        <DialogContent>
          <SearchClientAutoComplete {...props}/>
          </DialogContent> 
      </Dialog>
    </React.Fragment>
  );
}


export default AsignarClienteRuta;