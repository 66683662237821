
import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DetallesContadores from './detalle-contadores';
import GridDetalleCorte from './grid-detalle-corte';
import Divider from '@material-ui/core/Divider';
import GridDetalleMaquinaNoSistema from './grid-detalle-maquina-no-sistema';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GridAbonosClientes from './grid-abonos-cliente';
import GridPrestamosClientes from './grid-prestamos-cliente';
import GridMovimientoFondoMaquina from './grid-movimiento-fondo-maquina';
import GridMovimientoFondoNoSistema from './grid-movimiento-fondo-no-sistema';


const useStyles = makeStyles({
  display: {
    display : "block"
  },
});

const DetalleCorte = (props) => {
  const classes = useStyles();
  if (!props.data) {
    return "no data";
  }
  let cliente = props.data.map((e, i, a) => {

    return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary>
          {e.cliente.nombre}
        </AccordionSummary>
        <AccordionDetails className={classes.display}>
          <Divider orientation="vertical"/>
          <Typography>Detalle de corte</Typography>
          <GridDetalleCorte data={e.detalle_corte} porcentaje={e.porcentaje} />
          <Divider/>
          <Typography>Corte Maquina no sistema</Typography>
          <GridDetalleMaquinaNoSistema data={e.corte_no_sistema}/>
          <Typography>Abonos</Typography>
          <GridAbonosClientes data={e.movimientos_cliente}/>
          <Typography>Prestamos</Typography>
          <GridPrestamosClientes data={e.movimientos_cliente}/>
          <Typography>Moviento Fondo</Typography>
          <GridMovimientoFondoMaquina data={e.movimiento_fondo}/>
          <Typography>Moviento Fondo No Sistema</Typography>
          <GridMovimientoFondoNoSistema data={e.movimiento_fondo_no_sistema}/>
        </AccordionDetails>
      </Accordion>
      </React.Fragment>)
  })
  return (
    <React.Fragment>
      {cliente}
    </React.Fragment>
  )
}

export default DetalleCorte