import React from 'react'
import {
    Create,
    SimpleForm,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    AutocompleteInput
} from 'react-admin'

const MaquinaCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <NumberInput source="serie" />
            <ReferenceInput source="inventario_id" reference="inventarios" perPage={1000}><SelectInput optionText="prefijo" /></ReferenceInput>
            <ReferenceInput source="modelo_id" reference="modelos" perPage={200} sort={{field : 'nombre', order : 'DESC'}} >
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <SelectInput source="tipo" choices={[
                { id: 'Habilidad', name: 'Habilidad' },
                { id: 'Videojuego', name: 'Videojuego' },
            ]} />
            <TextInput source="descripcion" />
        </SimpleForm>
    </Create>
);

export default MaquinaCreate;