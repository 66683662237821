import React from 'react';
import {List, Datagrid, TextField} from 'react-admin';


const causasContadoresList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="nombre"/>
            <TextField source="descripcion"/>
        </Datagrid>
    </List>
);

export default causasContadoresList;