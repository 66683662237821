import React from 'react'
import {Filter, SearchInput,List,Datagrid,TextField,ReferenceField} from 'react-admin'

const PostFilter = props => (
    <Filter {...props}>
        <SearchInput  source="nombre"   />
        <SearchInput source="colonia" />
    </Filter>
    
);

const clienteList = props => (
    <List {...props} filters={<PostFilter/>}>
        <Datagrid rowClick="show">
            <TextField label="Nombre comercial" source="nom_com"/>
            <TextField source="nombre" />
            <TextField source="telefono" />
            <TextField source="celular" />
            <TextField source="domicilio" />
            <TextField source="colonia" />
            <ReferenceField label="Estado" source="estado_id" reference="estados" link={false}>
               <TextField source="nombre"/> 
            </ReferenceField>
            <ReferenceField label="Municipio" source="municipio_id" reference="municipios" link={false}>
               <TextField source="nombre"/> 
            </ReferenceField>
            <ReferenceField label="Localidad" source="localidad_id" reference="localidades" link={false}>
               <TextField source="nombre"/> 
            </ReferenceField>
            
        </Datagrid>
    </List>
);

export default clienteList;