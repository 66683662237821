import React from 'react';
import { Show, TabbedShowLayout, Tab, TextField, ReferenceField, SaveContextProvider } from 'react-admin';
import MaquinasAsignadasGrid from './datagridMaquinasAsignadas'
import AlertDialogSlide from './asignarMaquina';
import ActualizarPorcentaje from './actualizarPorcentaje';

const ClienteShow = (props) => (

    <Show {...props} >

        <TabbedShowLayout>
            <Tab label="Informacion">
                <TextField source="nom_com" />
                <TextField source="nombre" />
                <TextField source="telefono" />
                <TextField source="celular" />
                <TextField source="domicilio" />
                <TextField source="colonia" />
                <ReferenceField source="estado_id" reference="estados" link={false}><TextField source="nombre" /></ReferenceField>
                <ReferenceField source="municipio_id" reference="municipios" link={false}><TextField source="nombre" /></ReferenceField>
                <ReferenceField source="localidad_id" reference="localidades" link={false}><TextField source="nombre" /></ReferenceField>
                <ActualizarPorcentaje {...props} />
            </Tab>
            <Tab label="Maquinas asignadas">
                <AlertDialogSlide {...props} />
                <MaquinasAsignadasGrid {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ClienteShow;