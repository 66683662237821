import React, { useCallback } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import debounce from 'lodash/debounce'
import CircularProgress from "@material-ui/core/CircularProgress";




export default function BuscarEstado(props) {
	const [estados,setEstados] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState(null);
	const loading = open && estados.length === 0;

	const getEstadosDelayed = useCallback(
		debounce(input => {
			const searchParams = JSON.stringify({
				'type': 'TEXT',
				'data': { 'nombre': input },
				'range' : [0,24],
				'sort' : ["id" , "DESC"]
			});

			fetch(process.env.REACT_APP_API_KEY + "/estados?filter=" + searchParams, { headers: { 'Content-Type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` } })
				.then(response => response.json())
				.then(res => {
					setEstados(res);
				});
		}, 2000), []
	)


	return (
		<React.Fragment>
			<Autocomplete
				id="buscar-estado-autocomplete"
				open={open}
				onChange = {(event, value) => { 
					console.log("nueva opcion", value);
					props.updateEstado(value.id);
					setValue(value)
				}}
				onOpen={() => {
					setOpen(true)
				}}
				onClose={() => {
					setOpen(false);
				}}
				getOptionSelected={(option, value) => option.nombre === value.nombre}
				getOptionLabel={option => option.nombre}
				options = {estados}
				loading={loading}
				renderInput={params => (
					<TextField
					{...params}
					label="Buscar Estado"
					variant="outlined"
					onChange={ev => {
						if(ev.target.balue !== "" || ev.target.value !== null) {
							getEstadosDelayed(ev.target.value);
						}
					}}
					InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
				


			/>

		</React.Fragment>
	);
}
