import React from 'react'
import {Show, TabbedShowLayout,Tab,TextField} from 'react-admin'
import AsignarClienteRuta from './asignarClienteRuta'
import GridClientesRuta from './gridClientesRuta'
const RutaShow = (props) =>(
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Detalles">
                <TextField source="nombre"/>
                <TextField source="descripcion"/>
            </Tab>
            <Tab label="Clientes Asignados">
                <AsignarClienteRuta {...props}/>
                <GridClientesRuta {...props}/>
            </Tab>
        </TabbedShowLayout>
    </Show>
)

export default RutaShow;