import React from 'react'
import { Filter, ReferenceInput, AutocompleteInput, SearchInput,SelectInput} from 'react-admin'

const BuscarMaquinaFilter = props => (
    <Filter {...props} filterValues={{type:"FILTER_SEARCH"}}>
        <ReferenceInput source="inventario_id" reference="inventarios" ><SelectInput optionText="prefijo" />
        </ReferenceInput>
        <SearchInput source="serie" />
           <SelectInput source="tipo" choices={[
                { id: 'Habilidad', name: 'Habilidad' },
                { id: 'Videojuego', name: 'Videojuego' },
            ]} />
    </Filter>
);

export default BuscarMaquinaFilter;