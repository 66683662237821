import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const GridMovimientoFondoMaquina = (props) => {
  var data = props.data;
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="Prestamos">
          <TableHead>
            <TableRow>
              <TableCell align="center">Maquina</TableCell>
              <TableCell align="center">Cantidad</TableCell>
              <TableCell align="center">Tipo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="center">{`${row.maquina.inventario.prefijo}-${row.maquina.serie}`}</TableCell>
                  <TableCell align="center">{`$${row.cantidad}`}</TableCell>
                  <TableCell align="center">{`${row.motivo}`}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default GridMovimientoFondoMaquina;