import React from 'react'
import {Create, SimpleForm, TextInput} from 'react-admin';


const ModeloCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="descripcion" />
        </SimpleForm>
    </Create>
);
export default ModeloCreate;