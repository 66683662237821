import React, { useState } from 'react'
import keyBy from 'lodash/keyBy'
import { FunctionField, useListController, useQuery, Datagrid, TextField, Pagination, Loading, ReferenceField, ListContextProvider } from 'react-admin'


const ShowHistorialUbicaciones = (props) => {
    const [page, setPage] = useState(1);
    const perPage = 50;
    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'historialubicacion',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'id', order: 'ASC' },
            filter: {maquina_id : props.record.id },
        }
    });
        
    const controllerProps = useListController(props);
    if(!loading){
        controllerProps.data= keyBy(data, 'id');
        controllerProps.ids= data.map(({ id }) => id)
        controllerProps.currentSort={ field: 'id', order: 'ASC' }
    }
    if (loading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    return (
        <ListContextProvider value={controllerProps}>
            <Datagrid>
                 <ReferenceField {...props}
                    label="Ubicacion"                  
                    source= "ubicacion_id" 
                    reference="ubicaciones" 
                    link={false}>
                    <TextField source="nombre"/> 
                </ReferenceField>
                <TextField source="nota"/>
                <ReferenceField {...props}
                    label="Usuario"                  
                    source= "created_by" 
                    reference="usuarios" 
                    link={false}>
                    <TextField source="nombre"/> 
                </ReferenceField>     
                <FunctionField label="Fecha" render={record => 
                    
                    `${new Date(Date.parse(record.created_at)).toISOString().split('T')[0]}`
                } />
                </Datagrid>
        </ListContextProvider >
    )
}

export default ShowHistorialUbicaciones;