import React, { useState } from 'react'
import keyBy from 'lodash/keyBy'
import { FunctionField ,useListController, useQuery, Datagrid, TextField, Pagination, Loading, ReferenceField, ListContextProvider } from 'react-admin'


const ShowHistorialContadores = (props) => {
    const [page, setPage] = useState(1);
    const perPage = 50;
    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'historialcontadores',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'id', order: 'ASC' },
            filter: {maquina_id : props.record.id },
        }
    });
        
    const controllerProps = useListController(props);
    if(!loading){
        controllerProps.data= keyBy(data, 'id');
        controllerProps.ids= data.map(({ id }) => id)
        controllerProps.currentSort={ field: 'id', order: 'ASC' }
    }
    if (loading) {
        return <Loading />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    return (
        <ListContextProvider value={controllerProps}>
            <Datagrid>
                <TextField label="Valor" source="value"/>
                <FunctionField label="Tipo" render={record => `${record.tipo ? 'entrada' : 'salida'} `} />
                <ReferenceField {...props}
                    label="Motivo modificación"                  
                    source= "causa_id" 
                    reference="causacont" 
                    link={false}>
                    <TextField source="nombre"/> 
                </ReferenceField>
                <ReferenceField {...props}
                    label="Agregado por"                  
                    source= "created_by" 
                    reference="usuarios" 
                    link={false}>
                    <TextField source="nombre"/> 
                </ReferenceField>
                <FunctionField label="Fecha" render={record => 
                    
                    `${new Date(Date.parse(record.created_at)).toISOString().split('T')[0]}`
                } />
                
                </Datagrid>
        </ListContextProvider >
    )
}

export default ShowHistorialContadores;