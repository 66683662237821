
const authProvider =  {
    login: ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_API_KEY+'/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token ,usuario}) => {
                //const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('usuario', usuario);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('usuario')
        //localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: error => {
        // ...
        console.log("checkError");
        if(error.status === 403){
            return Promise.reject(error)
        } 
        console.log(error)
        return Promise.resolve(error)
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    
};

export default authProvider;