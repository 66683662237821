import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const GridDetalleMaquinaNoSistema = (props) => {
  let data = props.data;
  console.log(data);
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="Detalle Corte">
          <TableHead>
            <TableRow>
              <TableCell>Maquina</TableCell>
              <TableCell>Ingreso real</TableCell>
              <TableCell>Ingreso diversa</TableCell>
              <TableCell>Ingreso cliente</TableCell>
              <TableCell>Contador entrada</TableCell>
              <TableCell>Contador entrada servicio</TableCell>
              <TableCell>Contador salida</TableCell>
              <TableCell>Contador salida servicio</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="center">{`${row.inventario}-${row.serie}`}</TableCell>
                  <TableCell align="center">{`$${row.ingreso}`}</TableCell>
                  <TableCell align="center">{row.ingreso - (row.ingreso * (row.porcentaje / 100))}</TableCell>
                  <TableCell align="center">{row.ingreso * (row.porcentaje / 100)}</TableCell>
                  <TableCell>{row.cont_e_inicial}</TableCell>
                  <TableCell>{row.cont_e_servicio}</TableCell>
                  <TableCell>{row.cont_s_inicial}</TableCell>
                  <TableCell>{row.cont_s_servicio}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default GridDetalleMaquinaNoSistema;