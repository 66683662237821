import MaquinaList from './list'
import MaquinaCreate from './create'
import MaquinaShow from './show'
import MaquinaEdit from './edit'

export default {
    list : MaquinaList,
    create : MaquinaCreate,
    show : MaquinaShow,
    edit : MaquinaEdit
}