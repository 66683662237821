import React from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import { Admin,Resource } from 'react-admin';
import authProvider from './providers/Auth'
import apiProvider from './providers/Api'
import usuarios from './components/usuarios'
import clientes from './components/clientes'
import modelos from './components/modelos'
import productos from './components/productos'
import maquinas from './components/maquinas'
import inventarios from './components/inventarios'
import causasContadores from './components/causasContadores'
import rutas from './components/rutas'
import reportediaruta from './components/reportediaruta';

const messages = {
    'es': spanishMessages,
};
const i18nProvider = polyglotI18nProvider(() => spanishMessages, 'es');

const App = () => 

<Admin i18nProvider={i18nProvider}  authProvider={authProvider} dataProvider={apiProvider}>
    
    <Resource name="usuarios" {...usuarios} />
    <Resource name="clientes" {...clientes} />
    <Resource name="modelos" {...modelos} />
    <Resource name="maquinas" {...maquinas}/>
    <Resource name="inventarios" {...inventarios}/>
    <Resource name="causacont" options={{ label : 'Causa contadores' } } {...causasContadores}/>
    <Resource name="rutas" {...rutas}/>
    <Resource name="productos" {...productos}/>
    <Resource options={{ label : 'Reporte ruta' } } name="reportediaruta" {...reportediaruta}/>
    <Resource name="estados"/>
    <Resource name="localidades"/>
    <Resource name="municipios"/>
    <Resource name="historialcontadores" />
    <Resource name="historialingresomaquina"/>
    <Resource name="detalleruta"/>
    <Resource name="reporteMaquinaContadores"/>
    <Resource name="historialubicacion"/>
    <Resource name="ubicaciones"/>
</Admin>;

export default App;


