import React from 'react'
import {Edit,
        SimpleForm,
        ReferenceInput,
        SelectInput,
        TextInput} from 'react-admin'

const RutaEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="nombre" />
            <TextInput source="descripcion"/>
            <ReferenceInput source="encargado" reference="usuarios">
                <SelectInput optionText="nombre"/>
             </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default RutaEdit